<template>
  <EuiContainer>

    <!-- sign ad document or several dialog -->
    <SignDialog :signworkflows="signDialog" :open="isOpen" :hasParaphs="hasParaphs" :hasSigns="hasSigns" @close="signDialog = []"/>
    <refuseDialog :signworkflows="refuseDialog" @closeDialog="closeDialog" v-if="refuseDialog.length" />

    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiHeading :level="1" bold>
            <template v-if="this.actionType === 'sign'">
              {{ $t('guestSignworkflows.sign.title') }}
            </template>
            <template v-if="this.actionType === 'access'">
              {{ $t('guestSignworkflows.access.title') }}
            </template>
          </EuiHeading>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('guestSignworkflows.block.title')">
            <div class="eui-u-flex" :style="{visibility: selected.length ? 'visible' : 'hidden'}">
              <EuiTooltip :content="$tc('signworkflows.toolbar.sign-all', selected.length)"
                          placement="top"
                          :style="{ visibility: displaySignAllButton ? 'visible' : 'hidden' }">
                <template slot="anchor">
                  <EuiButton iconOnly color="primary" @click="signAll">
                    <EuiIcon name="pen"/>
                  </EuiButton>
                </template>
              </EuiTooltip>
              <!-- <EuiTooltip :content="$t('button.download')" placement="top">
                <template slot="anchor">
                  <EuiButton iconOnly color="primary" @click="downloadAll">
                    <EuiIcon name="download"/>
                  </EuiButton>
                </template>
              </EuiTooltip> -->
              <EuiTooltip :content="$t('signworkflows.toolbar.refuseAll')"
                          placement="top"
                          :style="{visibility: displayRefuseAllButton ? 'visible' : 'hidden'}">
                <template slot="anchor">
                  <EuiButton iconOnly color="primary"  @click="refuseAll">
                    <EuiIcon name="close"/>
                  </EuiButton>
                </template>
              </EuiTooltip>
            </div>
            <!-- signbooks table -->
            <EuiTable v-if="user && signworkflows.length > 0">
              <!-- table header -->
              <EuiTableHeader>
                <EuiTableRow>
                  <!-- checkbox and logo -->
                  <EuiTableHead dataType="input" v-if="actionType === 'sign'">
                    <EuiCheckbox v-model="selectAll"/>
                  </EuiTableHead>
                  <EuiTableHead dataType="input"></EuiTableHead>
                  <EuiTableHead :main=true>{{ $t('signworkflows.table.title') }}</EuiTableHead>
                  <EuiTableHead>
                    <span v-if="actionType === 'sign'">{{ $t('signworkflows.create.deadline') }}</span>
                    <span v-if="actionType === 'access'">{{ $t('signworkflows.create.completion') }}</span>
                  </EuiTableHead>
                  <EuiTableHead dataType="button"></EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>

              <!-- table body -->
              <EuiTableBody>
                <EuiTableRow v-for="item in signworkflows" :key="item.signBookId" @click.native="goToNext(item)">
                  <!-- checkbox -->
                  <EuiTableCell dataType="input" @click.native.stop  v-if="actionType === 'sign'">
                    <EuiCheckbox :value="item" v-model="selected" :disabled="!isEnabled(item)" />
                  </EuiTableCell>
                  <!-- icon -->
                  <EuiTableCell dataType="input">
                    <EuiIcon v-if="item.finalizedDate && !item.steps.find(x => parseInt(x.refuted))"
                             color="success"
                             name="check_circle"/>
                    <EuiIcon v-else-if="item.finalizedDate && item.steps.find(x => parseInt(x.refuted))"
                             color="error"
                             name="close"/>
                    <EuiIcon v-else-if="new Date(item.endingDate) < Date.now()"
                             color="error"
                             name="error"/>
                    <EuiIcon v-else-if="item.nextSignerName !== user.fullname"
                             color="primary"
                             name="pending"/>
                    <EuiIcon v-else
                             color="warning"
                             name="error"/>
                  </EuiTableCell>
                  <!-- title -->
                  <EuiTableCell :main=true>
                    {{ item.name }}
                  </EuiTableCell>
                  <!-- expiration date -->
                  <EuiTableCell>
                    <template v-if="actionType === 'sign' && item.nextStepDate">{{ $d(new Date(item.nextStepDate), 'short') }}</template>
                    <template v-if="actionType === 'access' && item.finalizedDate">{{ $d(new Date(item.finalizedDate), 'short') }}</template>
                  </EuiTableCell>
                  <EuiTableCell dataType="button">
                    <!-- if waiting-for-action -> sign and delete buttons
                    <div class="flex justify-end" v-if="isEnabled(item)">
                      <EuiButton color="primary" class="text-uppercase" @click.stop="$router.push({ name: 'guestSignworkflows.sign', params: { signBookId: item.signBookId } });">
                        {{ $t('signworkflows.toolbar.sign') }}
                      </EuiButton>
                    </div> -->
                      <EuiDropdownMenu v-if="isEnabled(item)">
                        <template v-slot:anchor>
                          <EuiButton iconOnly color="primary" @click.stop>
                            <EuiIcon name="more_vert"/>
                          </EuiButton>
                        </template>
                        <EuiList>
                          <EuiListItem :button="true"  @click.stop="$router.push({ name: 'guestSignworkflows.sign', params: { signBookId: item.signBookId } });">
                            {{ $t('signworkflows.toolbar.sign') }}
                          </EuiListItem>
                          <EuiListItem :button="true"  @click.stop="getArchive([item.signBookId, item.name])">
                            {{ $t('button.download') }}
                          </EuiListItem>
                        </EuiList>
                      </EuiDropdownMenu>
                    <div class="flex justify-end" v-else>
                      <EuiButton iconOnly color="primary" @click.stop="getArchive([item.signBookId, item.name])">
                        <EuiIcon name="download"/>
                      </EuiButton>
                    </div>
                  </EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
            <EuiPagination v-if="signworkflows.length"
                           class="mt-4"
                           :value="pagination.current"
                           @change="goToPage"
                           :paginationText="$t('pagination.text')"
                           :totalPages="pagination.total"/>
            <p class="es-subtitle" v-if="!signworkflows.length">{{ $t('signworkflows.table.none') }}</p>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'GuestSignworkflows',
  props: {
    noReset: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    SignDialog: () => import(/* webpackPrefetch: true */ '../../pages/signworkflows/dialog/signature/SignDialog'),
    refuseDialog: () => import(/* webpackPrefetch: true */ '../../pages/signworkflows/dialog/refuseDialog'),
  },
  data() {
    return {
      searchParams: {},
      selected: [],
      signDialog: [],
      refuseDialog: [],
    };
  },
  computed: {
    ...mapGetters({
      asideMenusIsOpened: 'ui/menusAreOpened',
      storeSearchParams: 'ModuleEdocSign/parapheurs/searchParams',
      signworkflows: 'ModuleEdocSign/parapheurs/signworkflows',
      pagination: 'ModuleEdocSign/parapheurs/pagination',
      user: 'ModuleEdocSign/auth/user',
    }),
    isOpen: {
      get() {
        return this.signDialog && this.signDialog.length > 0;
      },
      set(value) {
        if (!value) {
          this.signDialog = [];
        }
      },
    },
    actionType() {
      if (this.$route.name === 'guestSignworkflows') {
        return 'sign';
      }
      if (this.$route.name === 'guestSignworkflows.access') {
        return 'access';
      }
      return null;
    },
    selectAll: {
      get() {
        if (this.signworkflows && this.selected.length > 0) {
          return this.signworkflows.every((value) => {
            for (let i = 0; i < this.selected.length; i++) {
              if (this.selected[i].signBookId === value.signBookId) {
                return true;
              }
            }
            return false;
          });
        }
        return false;
      },
      set(value) {
        if (value) {
          this.signworkflows.forEach((signatory) => {
            if (this.isEnabled(signatory)) {
              this.selected.push(signatory);
            }
          });
        } else {
          const selected = [];
          this.signworkflows.forEach((item) => {
            let contains = false;
            for (let i = 0; i < this.signworkflows.length; i++) {
              if (this.signworkflows[i].signbookId === item.signbookId) {
                contains = true;
                break;
              }
            }
            if (!contains) {
              selected.push(item);
            }
          });
          this.selected = selected;
        }
      },
    },
    displaySignAllButton() {
      return this.actionType === 'sign' && !this.hasTextZones &&
        this.selected.filter((x) => x.nextSignerName === this.user.fullname &&
          new Date(x.nextStepDate) > Date.now()).length;
    },
    hasParaphs() {
      let result = false;
      this.selected.forEach(x => x.steps.forEach(y => y.captiveZones.forEach(z => {
        if (z.zoneType === 'paraph') result = true;
      })));
      return result;
    },
    hasSigns() {
      let result = false;
      this.selected.forEach(x => x.steps.forEach(y => y.captiveZones.forEach(z => {
        if (z.zoneType === 'signature') result = true;
      })));
      return result;
    },
    hasTextZones() {
      let result = false;
      this.selected.forEach(x => x.steps.forEach(y => y.captiveZones.forEach(z => {
        if (z.zoneType === 'textfield') result = true;
      })));
      return result;
    },
    displayRefuseAllButton() {
      return this.actionType === 'sign' && this.selected.filter((x) => x.nextSignerName === this.user.fullname &&
        parseInt(x.allowDecline) &&
        new Date(x.nextStepDate) > Date.now()).length;
    },
  },
  methods: {
    ...mapActions({
      getSignworkflows: 'ModuleEdocSign/parapheurs/getSignworkflows',
      getArchive: 'ModuleEdocSign/parapheurs/getArchive',
      resetSearchParams: 'ModuleEdocSign/parapheurs/resetSearchParams',
      getUserInfos: 'ModuleEdocSign/auth/getUserInfos',
    }),
    isEnabled(item) {
      return item.nbStepsRealized < item.nbSteps &&
      item.nextSignerName === this.user.fullname &&
        new Date(item.nextStepDate) > Date.now();
    },
    signAll() {
      this.signDialog = this.selected.filter((x) => x.nextSignerName === this.user.fullname &&
        new Date(x.nextStepDate) > Date.now());
    },
    downloadAll() {
      this.selected.forEach((item) => {
        this.getArchive([item.signBookId, item.name]);
      });
    },
    refuseAll() {
      this.refuseDialog = this.selected.filter((x) => x.nextSignerName === this.user.fullname &&
        parseInt(x.allowDecline) &&
        new Date(x.nextStepDate) > Date.now());
    },
    closeDialog() {
      this.signDialog = [];
      this.refuseDialog = [];
    },
    selectSignWorkflow(item) {
      if (this.selected.includes(item)) {
        this.selected.splice(this.selected.indexOf(item), 1);
      } else {
        this.selected.push(item);
      }
      this.checkAllSelected();
    },
    goToNext(item) {
      if (this.actionType === 'sign' && this.isEnabled(item)) {
        this.$router.push({ name: 'guestSignworkflows.sign', params: { signBookId: item.signBookId } });
      } else {
        this.$router.push({ name: 'detailsGuestSignworkflow', params: { signBookId: item.signBookId } });
      }
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.getSignworkflows(this.searchParams);
    },
  },
  mounted() {
    if (!this.noReset) {
      this.resetSearchParams();
    }
    this.searchParams = JSON.parse(JSON.stringify(this.storeSearchParams));

    if (this.actionType === 'sign') {
      this.searchParams.params[0].paramValue = 'waiting-for-action';
    } else if (this.actionType === 'access') {
      this.searchParams.params[0].paramValue = 'completed';
    }

    this.getSignworkflows(this.searchParams);
    if (this.user === undefined) {
      this.getUserInfos();
    }
  },
};
</script>
